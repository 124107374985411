import { logger } from "../logger";
import { isDevelopmentMode } from "../utils/importMeta";
import { AdobeAnalytics } from "./adobeAnalytics";
import { Decibel } from "./decibel";
import { type PageName } from "./tracking";

/**
 * Identifies users on Decibel and AdobeAnalytics.
 *
 * @param buyerId
 * @param email
 * @param phone
 */
function identify({
	buyerId,
	email,
	phone,
}: {
	email?: string;
	phone?: string;
	buyerId: string;
}): void {
	Decibel.identify(buyerId, { email, phone });
	AdobeAnalytics.identify(buyerId);
}

function clearEventProperties(): void {
	AdobeAnalytics.clearEventProperties();
}

function addEventProperties(properties: Record<string, string>): void {
	AdobeAnalytics.addEventProperties(properties);
}

function trackEvent(eventName: string, props: Record<string, unknown> = {}): void {
	AdobeAnalytics.trackEvent(eventName, props);
}

function trackPageView(pageName: PageName): void {
	AdobeAnalytics.trackPageView(pageName);
}

const tracking = {
	identify,
	trackEvent,
	addEventProperties,
	clearEventProperties,
	trackPageView,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function logAnalytics(fn: (..._: any[]) => void) {
	return (...args: unknown[]) =>
		logger.info(`Tracking: called '${fn.name}' with: ${JSON.stringify(args)}`);
}

const noOpTracking: typeof tracking = {
	identify: logAnalytics(identify),
	trackEvent: logAnalytics(trackEvent),
	addEventProperties: logAnalytics(addEventProperties),
	clearEventProperties: logAnalytics(clearEventProperties),
	trackPageView: logAnalytics(trackPageView),
};

export const trackingLib = !isDevelopmentMode() ? tracking : noOpTracking;
