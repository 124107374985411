import axios from "@getbread/api-client-axios";
import { QueryClient, type QueryClientConfig, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import {
	type AxiosError,
	type AxiosResponse,
	type InternalAxiosRequestConfig,
	type Method,
} from "axios";
import { isEmpty } from "ramda";
import { InvalidJwtApiError } from "./api-client/authentication";
import { type FCWithChildren } from "./api-client/resources/types/sharedTypes";
import { jwtStorageKey } from "./components/AuthenticationProvider";
import { logger } from "./logger";
import { getConfig } from "./utils/getConfig";
import { getItem } from "./utils/localStorage";

export const buildAuthorizationHeader = (jwt = getItem(jwtStorageKey)) => ({
	authorization: `Bearer ${jwt}`,
});

const logRequest = (config: InternalAxiosRequestConfig) => {
	logger.debug({
		msg: "--> API Request",
		method: config.method as Method,
		url: config.url!,
	});
};

const logResponse = (response: AxiosResponse): AxiosResponse => {
	logger.debug({
		method: response.config.method,
		msg: "<-- API Response",
		status: response.status,
		url: response.config.url,
	});
	return response;
};

const logErrorResponse = (response: AxiosResponse): AxiosResponse => {
	logger.error({
		method: response.config.method,
		msg: "<-- API Response",
		status: response.status,
		url: response.config.url,
		responseHeaders: response.headers,
		requestHeaders: response.config.headers,
	});
	return response;
};

export const queryClientConfig: QueryClientConfig = {
	defaultOptions: {
		queries: {
			// don't do API requests for cache updating when the window regains focus
			refetchOnWindowFocus: false,
			// always consider an API request "fresh", and never update it in the background
			staleTime: Infinity,
			// don't bother retrying API requests, just fail the first time
			retry: false,
			// use suspense and error boundaries for fetch queries
			suspense: true,
		},
	},
};

const mpQueryClient = new QueryClient(queryClientConfig);

const getServiceNameFromUrl = (url?: string) => {
	if (!url) return undefined;
	const serviceNameMatch = /\/api\/([A-Za-z0-9\-_]+)/.exec(url);
	return serviceNameMatch && serviceNameMatch.length >= 2 ? serviceNameMatch[1] : undefined;
};

export const apiRequestCustomizer = (config: InternalAxiosRequestConfig) => {
	config.baseURL = getConfig("apiurl");

	if (getServiceNameFromUrl(config.url) === "auth") {
		config.headers["X-Bread-App-ID"] = getConfig("appid");
	}

	const jwt = getItem<string>(jwtStorageKey);
	if (!config.headers.authorization && jwt) {
		config.headers.authorization = `Bearer ${jwt}`;
	}
	logRequest(config);

	return config;
};

axios.interceptors.request.use(apiRequestCustomizer);

axios.interceptors.response.use(logResponse, (error: AxiosError) => {
	if (error.response) {
		logErrorResponse(error.response);
	}
	// Auth calls have special error handling in authentication.ts, so don't transform here.
	if (error.response?.status === 401 && getServiceNameFromUrl(error.config?.url) !== "auth") {
		throw new InvalidJwtApiError();
	} else if (!isEmpty(error.response?.data)) {
		// unwrap axios error response data
		throw error.response?.data;
	} else if (error.response) {
		// unwrap axios response
		// eslint-disable-next-line @typescript-eslint/only-throw-error
		throw error.response;
	} else {
		throw error;
	}
});

export const ApiClientProvider: FCWithChildren<{ queryClient?: QueryClient }> = ({
	queryClient = mpQueryClient,
	children,
}) => (
	<QueryClientProvider client={queryClient}>
		<>
			{children}
			<ReactQueryDevtools initialIsOpen={false} />
		</>
	</QueryClientProvider>
);
