import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { getBuyer } from "@getbread/api-client-buyer-v2";
import { Button, type Locale } from "@getbread/dough";
import { Box, CircularProgress } from "@mui/material";
import { useUpdateBuyer } from "../../api-client/hooks/buyer";
import { stdSpacing } from "../../global.style";
import { PageName, tracking } from "../../tracking/tracking";
import { useTrackPageView } from "../../tracking/useTrackPageView";
import { useErrorMessage } from "../../utils/useErrorMessage";
import { useAuthentication } from "../AuthenticationProvider";
import { GenericErrorMessage } from "../errors/GenericErrorBoundary";
import { useLocale } from "../LocaleProvider";
import { ModalSmall } from "../shared/Shared.style";

type ConfirmLanguageChangeProps = { locale: Locale; onClose: () => void };

const ConfirmLanguageChangeModal: React.FC<ConfirmLanguageChangeProps> = ({ locale, onClose }) => {
	const [isLoading, setIsLoading] = useState(false);
	const { buyerId } = useAuthentication();
	const { setError, clearError, ErrorMessage } = useErrorMessage();
	const { changeLocale } = useLocale();
	const { mutateAsync: updateBuyer } = useUpdateBuyer();
	useTrackPageView(PageName.ChangeLanguagePage);

	const setLocale = async () => {
		tracking.trackClickYesChangeLanguage();
		setIsLoading(true);
		clearError();

		try {
			// buyer does not support partial updates, so first fetch the current buyer preferences...
			const buyerDetails = (await getBuyer(buyerId)).data;

			// ... then update the buyer with those preferences and the new language preference
			await updateBuyer({
				id: buyerId,
				data: {
					...buyerDetails,
					languagePreference: locale,
				},
			});

			changeLocale(locale);
		} catch (e) {
			setIsLoading(false);
			setError(<GenericErrorMessage />);
		}
	};

	return (
		<Box component="section" display="flex" flexDirection="column" alignItems="center">
			<FormattedMessage defaultMessage="By changing your language preference, you'll also start receiving all your communications in your new language preference." />
			<ErrorMessage />
			<Box
				marginTop={stdSpacing.l}
				marginBottom={stdSpacing.m}
				width="100%"
				textAlign="center"
			>
				{isLoading ? (
					<CircularProgress />
				) : (
					<Button
						variant="contained"
						color="primary"
						onClick={setLocale}
						fullWidth
						data-testid="change-language-confirm-button"
					>
						<FormattedMessage defaultMessage="Yes, Change My Language" />
					</Button>
				)}
			</Box>
			<Button
				variant="text"
				color="primary"
				onClick={onClose}
				data-testid="keep-language-button"
			>
				<FormattedMessage defaultMessage="No, keep my current language" />
			</Button>
		</Box>
	);
};

export const ConfirmLanguageChange: React.FC<ConfirmLanguageChangeProps> = ({
	locale,
	onClose,
}) => {
	const intl = useIntl();

	const options = {
		onClose,
		open: true,
	};

	const step = {
		component: ConfirmLanguageChangeModal,
		heading: intl.formatMessage({ defaultMessage: "Change language preference?" }),
		props: { locale, onClose },
	};

	return <ModalSmall options={options} initialStep={step} ariaModalId="standard-modal" />;
};

export function useConfirmLanguageChange() {
	const [changeLocale, setChangeLocale] = useState<Locale>();

	const LanguageChangeModal = changeLocale ? (
		<ConfirmLanguageChange locale={changeLocale} onClose={() => setChangeLocale(undefined)} />
	) : null;

	return [setChangeLocale, LanguageChangeModal] as const;
}
