import { FormattedMessage } from "react-intl";
import { pxToRem } from "@getbread/dough";
import { styled } from "@mui/material/styles";
import { getStructureConfig } from "../../utils/getConfig";
import { FooterLinks } from "./FooterLinks";

const FooterContainer = styled("footer")`
	padding: var(--space-s) var(--space-m);
`;

const RBCLegalText = styled("div")`
	font-size: ${pxToRem(10)};
	line-height: ${pxToRem(24)};
	color: ${({ theme }) => theme.palette.text.secondary};
`;

export const Footer: React.FC = () => {
	const footer = getStructureConfig("footer");

	return footer ? (
		<FooterContainer data-testid="footer">
			<FooterLinks />
			<RBCLegalText>
				<FormattedMessage defaultMessage="PayPlan by RBC member portal is provided by RBC Ampli Inc." />
			</RBCLegalText>
		</FooterContainer>
	) : null;
};
