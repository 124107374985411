import { useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useToast } from "@getbread/dough";
import { InvalidJwtApiError } from "../../api-client/authentication";
import { type FCWithChildren } from "../../api-client/resources/types/sharedTypes";
import { useAuthentication } from "../AuthenticationProvider";

const InvalidJwtErrorComponent: React.FC<{ error: unknown }> = ({ error }) => {
	const { logOut } = useAuthentication();
	const { showToast } = useToast();

	useEffect(() => {
		if (error instanceof InvalidJwtApiError) {
			showToast({
				message: "You have been logged out. Please log in again.",
				severity: "error",
			});
			logOut();
		}
	}, [error, showToast, logOut]);

	if (!(error instanceof InvalidJwtApiError)) {
		throw error;
	}
	return null;
};

export const InvalidJwtErrorBoundary: FCWithChildren = ({ children }) => (
	<ErrorBoundary FallbackComponent={InvalidJwtErrorComponent}>{children}</ErrorBoundary>
);
