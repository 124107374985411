import { FormattedMessage } from "react-intl";
import { pickBy } from "ramda";
import { intl } from "../components/LocaleProvider";
import { getConfig } from "./getConfig";
import { EMPTY_TRANSLATION } from "./global.constants";

/**
 * All possible languages, translated
 */
export function allLanguageNames() {
	return {
		"en-us": intl.formatMessage({ defaultMessage: "English" }),
		"en-ca": intl.formatMessage({ defaultMessage: "English" }),
		"fr-ca": intl.formatMessage({ defaultMessage: "French" }),
	};
}

/**
 * All possible language short names, translated
 */
export function allAbbreviatedLanguageNames() {
	return {
		"en-us": intl.formatMessage({ defaultMessage: "EN" }),
		"en-ca": intl.formatMessage({ defaultMessage: "EN" }),
		"fr-ca": intl.formatMessage({ defaultMessage: "FR" }),
	};
}

/**
 * The list of enabled languages for a tenant, translated
 */
export function languageNames(): Record<string, string> {
	const l = getConfig("locales");
	return pickBy((_, locale) => l.includes(locale), allLanguageNames());
}

/**
 * The list of enabled language short names for a tenant, translated
 */
export function abbreviatedLanguageNames(): Record<string, string> {
	const l = getConfig("locales");
	return pickBy((_, locale) => l.includes(locale), allAbbreviatedLanguageNames());
}

/**
 * A translation of each _type_ of payment provider
 */
export function paymentProviderTypeNames() {
	return {
		BANK_ACCOUNT: <FormattedMessage defaultMessage="Bank Account Provider" />,
		DEBIT_CARD: <FormattedMessage defaultMessage="Debit Card Provider" />,
		CREDIT_CARD: <FormattedMessage defaultMessage="Credit Card Provider" />,
	};
}

/**
 * Check is a translation is an empty string or not
 * @param string the translation string
 */
export function isEmptyTranslation(string: string) {
	return string === EMPTY_TRANSLATION;
}
