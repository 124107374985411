import { type SyntheticEvent } from "react";
import { Divider, type Locale } from "@getbread/dough";
import { styled } from "@mui/material/styles";
import { languageNames } from "../../utils/i18n";
import { useLocale } from "../LocaleProvider";
import { HeaderLinkButton } from "./HeaderLink";

const LinkDivider = styled(Divider)`
	margin: var(--space-m) 0;
`;

const HamburgerLanguages: React.FC<{ changeLanguage: (locale: Locale) => void }> = ({
	changeLanguage,
}) => {
	const { locale } = useLocale();
	const languages = languageNames();
	return (
		<>
			<LinkDivider />
			{Object.keys(languages).map((langLocale) => (
				<HeaderLinkButton
					key={langLocale}
					data-testid={`hamburger-language-${langLocale}`}
					onClick={(e: SyntheticEvent) => {
						if (langLocale === locale) {
							return;
						}

						e.stopPropagation();
						changeLanguage(langLocale as Locale);
					}}
				>
					{languages[langLocale]}
				</HeaderLinkButton>
			))}
		</>
	);
};

export { HamburgerLanguages };
