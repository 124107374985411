import { ErrorBoundary } from "react-error-boundary";
import { FormattedMessage } from "react-intl";
import { type FCWithChildren } from "../../api-client/resources/types/sharedTypes";
import { logger } from "../../logger";
import { ErrorMessageContainer } from "./ErrorMessageContainer";

export const GenericErrorMessage = () => {
	return <FormattedMessage defaultMessage="Something went wrong. Please contact support" />;
};

const ErrorComponent: React.FC = () => (
	<ErrorMessageContainer data-testid="generic-error-message">
		<GenericErrorMessage />
	</ErrorMessageContainer>
);

export const GenericErrorBoundary: FCWithChildren = ({ children }) => (
	<ErrorBoundary
		FallbackComponent={ErrorComponent}
		onError={(error, errorInfo) => {
			logger.error({ error, errorInfo });
		}}
	>
		{children}
	</ErrorBoundary>
);
