import { useCallback, useState } from "react";
import { getItem, setItem } from "./localStorage";

// inspired by https://usehooks.com/useLocalStorage/
function useLocalStorage<T>(key: string, initialValue: T) {
	const [storedValue, setStoredValue] = useState<T>(() => {
		try {
			const item = getItem<T>(key);
			return item !== null ? item : initialValue;
		} catch (error) {
			return initialValue;
		}
	});

	const setValue = useCallback(
		(value: T | ((v: T) => T)) => {
			try {
				const valueToStore = value instanceof Function ? value(storedValue) : value;

				setStoredValue(valueToStore);
				setItem(key, valueToStore);
			} catch (e) {
				// do nothing
			}
		},
		[key, storedValue],
	);

	return [storedValue, setValue] as const;
}

export { useLocalStorage };
