import { type Buyer } from "@getbread/api-client-buyer-v2";
import type { ValueOf } from "type-fest";
import { trackingLib } from "./trackingLib";

// User Flows
export const FlowType = {
	MAKE_A_PAYMENT: "Make a Payment",
	MANAGE_AUTOPAY: "Manage AutoPay",
	ENROLL_IN_AUTOPAY: "Enroll in AutoPay",
	ACCOUNT_PAGE: "Account Page",
} as const;

export type FlowType = ValueOf<typeof FlowType>;

// untranslated payment method types
export const trackingPaymentMethodTypes = {
	DEBIT: "Debit Card",
	CREDIT: "Credit Card",
	BANK_ACCOUNT: "Bank Account",
} as const;

export type BuyerLoanTrackingData = {
	loanID: string;
	merchantID: string;
	programID: string;
	programName: string;
	loanStatus: string;
	loanSubStatus: string;
	paymentProductName: string;
	merchantName: string;
};

export type TrackingPaymentMethodType = ValueOf<typeof trackingPaymentMethodTypes>;

export const PageName = {
	LoginProductSelectorPage: "Login Product Selector Page",
	LoginProductSelectorDatePage: "Login Product Selector Page – Date",
	LoginProductDifferencePage: "Login Product Difference Page",
	LoginEmailPage: "Login Email Page",
	LoginMobilePage: "Login Mobile Page",
	LoginVerificationCodePage: "Login Verification Code Page",
	PaymentPlansPage: "Payment Plans Page",
	OutstandingLoansPage: "Outstanding Loans Page",
	HistoryPage: "History Page",
	ClosedLoansPage: "Closed Loans",

	// Upload ID Page Names
	UploadIDVerificationPage: "ID Verification Page",
	UploadFrontIDPage: "Upload Front ID Page",
	UploadBackIDPage: "Upload Back ID Page",
	UploadConfirmIDPhotosPage: "Confirm ID Photos Page",
	UploadIDPhotosPage: "Uploading ID Photos Page",

	// ID Page Names
	IDFailPage: "ID Fail Page",
	IDPassLowRiskPage: "ID Pass Low Risk Page",
	IDPassExtendedFraudAlertPage: "ID Pass Extended Fraud Alert Page",
	IDPassHighRiskPage: "ID Pass High Risk Page",

	// Make A Payment Names
	MakeAPaymentPage: "Make A Payment Page",
	PaymentSuccessPage: "Payment Success Page",
	ReviewPaymentPage: "Review Payment Page",
	ReviewAndPayPage: "Review & Pay Page",

	// Autopay Names
	EnrollInAutoPayPage: "Enroll In AutoPay Page",
	AutoPaySuccessPage: "AutoPay Success Page",
	ReviewAutoPayPage: "Review AutoPay Page",
	ReviewAndEnrollInAutoPayPage: "Review & Enroll In AutoPay Page",
	ManageAutoPayPage: "Manage AutoPay Page",
	ManageAutoPayPageTurnOff: "Manage AutoPay Page - Turn Off",

	// Account Detail Page Names
	AccountDetailsPage: "Account Details Page",
	EditEmailPage: "Edit Email Page",
	VerifyEmailPage: "Verify Email Page",
	EditEmailSuccessPage: "Edit Email Success Page",
	EditMobilePage: "Edit Mobile Page",
	VerifyMobilePage: "Verify Mobile Page",
	EditMobileSuccessPage: "Edit Mobile Success Page",
	EditAddressPage: "Edit Address Page",
	EditAddressSuccessPage: "Edit Address Success Page",
	DeletePaymentMethodPageConfirmRemoval: "Delete Payment Method Page - Confirm Removal",
	DeletePaymentMethodPageAutoPayEnrolled: "Delete Payment Method Page - AutoPay Enrolled",

	// Shared Payment and Autopay Name Suffixes
	AddPaymentMethod: "Add Payment Method",
	AddAnotherPaymentMethod: "Add Another Payment Method",
	SelectExistingPaymentMethod: "Select Existing Payment Method",

	// RBC Specific
	ChangeLanguagePage: "Change Language Page",
	ConnectAnAccountPage: "Connect An Account Page",
	TermsAndConditions: "Terms And Conditions",
	Login: "Login",
} as const;

export type PageName = ValueOf<typeof PageName>;

/**
 * @see user flows https://docs.google.com/spreadsheets/d/1dPlynS2of7ir_NRmUuFbtkSRppo5trhceWAHb32C4hQ
 */
export const tracking = {
	registerBuyer(buyerId: string, buyer: Buyer) {
		trackingLib.identify({
			buyerId,
			email: buyer.identity.email,
			phone: buyer.identity.phone,
		});
	},

	trackPageView(pageName: PageName): void {
		trackingLib.trackPageView(pageName);
	},

	trackSuccessfulLogin(): void {
		trackingLib.trackEvent("login_success");
	},

	trackLoginFailure(): void {
		trackingLib.trackEvent("login_failure");
	},

	trackBuyerLoansData(data: BuyerLoanTrackingData[]): void {
		trackingLib.trackEvent("buyer_loans_data", { data });
	},

	trackIncorrectCode(): void {
		trackingLib.trackEvent("incorrect_code");
	},

	trackTooltipClick(): void {
		trackingLib.trackEvent("click_tooltip");
	},

	trackClickCameraIcon(): void {
		trackingLib.trackEvent("click_camera_icon");
	},

	trackClickOrderDetails(): void {
		trackingLib.trackEvent("click_order_details");
	},

	trackClickPaymentPlanDetails(): void {
		trackingLib.trackEvent("click_payment_plan_details");
	},

	trackClickActivityAndPaymentSchedule(): void {
		trackingLib.trackEvent("click_activity_and_payment_schedule");
	},

	trackClickMakePaymentAmountType(type: string): void {
		trackingLib.trackEvent("click_make_payment_amount_type", { type });
	},

	trackSaveNewPaymentMethod(): void {
		trackingLib.trackEvent("click_save_new_payment_method");
	},

	trackClickAutopayToggleOn(): void {
		trackingLib.trackEvent("click_autopay_toggle_on");
	},

	trackClickAutopayToggleOff(): void {
		trackingLib.trackEvent("click_autopay_toggle_off");
	},

	trackClickDeletePaymentMethod(): void {
		trackingLib.trackEvent("click_delete_payment_method");
	},

	trackClickYesDeletePaymentMethod(): void {
		trackingLib.trackEvent("click_yes_delete_payment_method");
	},

	trackClickYesChangeLanguage(): void {
		trackingLib.trackEvent("click_yes_change_language");
	},

	trackManageAutopayUpdatePaymentMethod(): void {
		trackingLib.trackEvent("manage_autopay_update_payment_method");
	},

	trackEnrollAutopay(): void {
		trackingLib.trackEvent("manage_autopay_enroll");
	},

	trackUnenrollAutopay(): void {
		trackingLib.trackEvent("manage_autopay_unenroll");
	},

	trackImageUploadError(
		type:
			| "file_size"
			| "file_type"
			| "duplicate_side"
			| "blurry"
			| "glare"
			| "unclear"
			| "expired",
	): void {
		trackingLib.trackEvent("error_image_upload", { type });
	},

	/*
	GLBA
	*/
	trackGlbaOptOutClick() {
		trackingLib.trackEvent("click_opt_out_toggle_on");
	},

	trackDoNotShareMyInformationClick() {
		trackingLib.trackEvent("click_do_not_share_my_information");
	},

	trackFinancialPrivacyPolicyClick() {
		trackingLib.trackEvent("click_financial_privacy_policy");
	},
};
